<template>
  <div class="d-flex flex-column align-items-center">
    <h5 class="font-weight-bold align-self-center">{{ media.title }}</h5>

    <div class="d-flex flex-column flex-md-row mt-2 mb-4 align-items-center">
      <button @click="$emit('change')" class="btn btn-link side-icon-btn">
        <hs-icon icon="exchange" />
        <span class="ml-1">
          {{
            $t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-media.components.set.change-button-label'
            )
          }}
        </span>
      </button>

      <button @click="$emit('view')" class="btn btn-link side-icon-btn">
        <hs-icon icon="eye" />
        <span class="ml-1">
          {{
            $t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-media.components.set.view-button-label'
            )
          }}
        </span>
      </button>

      <button v-if="media.type === 'Text'" @click="$emit('edit')" class="btn btn-link side-icon-btn">
        <hs-icon icon="pencil" />
        <span class="ml-1">
          {{
            $t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-media.components.set.edit-button-label'
            )
          }}
        </span>
      </button>
    </div>

    <div class="mb-2">
      <p class="d-inline font-weight-bold mb-1">
        Adicionar uma imagem de capa
        <hs-icon v-if="Boolean(media)" id="thumbnail-info" class="ml-2" icon="info-circle" :size="14" />
      </p>
      <p v-if="!Boolean(media)">Personalize a capa de cada uma das suas aulas.</p>

      <b-popover v-if="Boolean(media)" triggers="hover" target="thumbnail-info" custom-class="thumbnail-info-popover">
        Formatos: .jpeg, .jpg ou .png, dimensões ideais 1280 x 720px e 1MB.
      </b-popover>
    </div>

    <Thumbnail
      :isLoading="isThumbnailLoading"
      :source="coverImageUrl"
      :showMessage="false"
      @selected-image="$listeners['selected-image']"
      @remove-image="$listeners['remove-image']"
    />
  </div>
</template>

<script>
import { lookup as mimeByExtension } from 'mime-types';
import Thumbnail from '@/components/Thumbnail';

const PDF = [mimeByExtension('.pdf')];

const SHEET = [mimeByExtension('.xls'), mimeByExtension('.xlsx'), mimeByExtension('.csv')];

const documentByMimeTypeFromURL = url => {
  const mime = mimeByExtension(url);
  return (PDF.includes(mime) && 'pdf') || (SHEET.includes(mime) && 'sheet') || null;
};

export default {
  name: 'MediaOverview',
  components: {
    Thumbnail,
  },
  props: {
    isThumbnailLoading: {
      type: Boolean,
      default: false,
    },
    coverImage: {
      type: Object,
    },
    media: {
      type: Object,
    },
  },
  computed: {
    coverImageUrl() {
      return this.coverImage?.s3_file_url;
    },
  },
};
</script>
<style lang="scss" scoped>
.cover-image {
  height: auto;

  img {
    width: 194px;
    height: 108px;
  }
}

.side-icon-btn {
  color: $purple-dark;
  span {
    font-weight: 400;
    font-size: 14px;
  }
  &:hover {
    span {
      text-decoration: underline;
    }
  }
}
</style>
